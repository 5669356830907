import { useEffect, useState } from 'react'
import { Stream } from '@cloudflare/stream-react'

import useAppContext from 'app/store/useAppContext'
import { videoIdOrSignedToken } from 'shared/config/videoIdOrSignedToken'
import TicketIcon from 'shared/assets/icons/ticket.webp'
import AlertIcon from 'shared/assets/icons/alert.webp'

import { data } from '../model/data'

import cls from './RegisteredPage.module.scss'
import { useReactPixelMetric } from 'entities/user'

export const RegisteredPage = () => {
  const {
    store: {
      user: { info },
    },
  } = useAppContext()

  const [selectedRandomData, setselectedResultsData] = useState([data[0]])

  useReactPixelMetric()

  useEffect(() => {
    const interval = setInterval(() => {
      let randomResultsData = data.sort(() => 0.5 - Math.random())
      let selectedRandom = randomResultsData.slice(0, 1)
      setselectedResultsData(selectedRandom)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const [countDown, setCountDown] = useState(20)
  const [runTimer, setRunTimer] = useState(true)

  useEffect(() => {
    let timerId: any

    if (runTimer) {
      setCountDown(20)
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1)
      }, 60000)
    } else {
      clearInterval(timerId)
    }

    return () => clearInterval(timerId)
  }, [runTimer])

  useEffect(() => {
    if (countDown < 1 && runTimer) {
      setRunTimer(false)
      setCountDown(1)
    }
  }, [countDown, runTimer])

  const [regDate, setRegDate] = useState([])

  useEffect(() => {
    if (regDate) {
      let currentDate = new Date()
      let year = currentDate.getFullYear()
      let date = String(currentDate.getDate()).padStart(2, '0')
      let hours = String(currentDate.getHours()).padStart(2, '0')
      let minutes = String(currentDate.getMinutes()).padStart(2, '0')
      let month = String(currentDate.getMonth() + 1).padStart(2, '0')
      // @ts-ignore
      setRegDate([date, month, year, hours, minutes])
    }
  }, [])

  return (
    <div className={cls.complete_MainContainer}>
      <div className={cls.complete_ContentMainContainer}>
        <div className={cls.complete_ContentContainer}>
          <div className={cls.complete_LeftContainer}>
            {selectedRandomData.map(
              ({
                id,
                fullName,
                transactionNumber,
                transactionValue,
                transactionTime,
              }) => (
                <div
                  key={id}
                  className={`${cls.slideLeft} ${cls.complete_LeftTopBox} `}
                >
                  <div className={`${cls.complete_RightTopBoxLeft} `}>
                    <div className={cls.complete_TopBoxName}>
                      {fullName}
                    </div>
                    <div className={cls.complete_TopBoxNumbers}>
                      {transactionNumber}
                    </div>
                  </div>
                  <div className={cls.complete_RightTopBoxRight}>
                    <div className={cls.complete_TopBoxValue}>
                      {transactionValue}
                    </div>
                    <div className={cls.complete_TopBoxTime}>
                      {transactionTime}
                    </div>
                  </div>
                </div>
              ),
            )}
            <div className={cls.complete_LeftTop}>
              <div className={cls.complete_Head}>Поздравляем!</div>
              <div className={cls.complete_Desc}>
                <div>Ожидайте звонка от нашего</div>
                <div>менеджера, чтобы открыть</div>
                <div>инвестиционный счет!</div>
              </div>
            </div>
            <div className={cls.complete_TicketContainer}>
              <div className={cls.complete_TicketImg}>
                <img src={TicketIcon} alt="ticket" />
              </div>
              <div className={cls.complete_TicketContent}>
                <div className={cls.complete_TicketHead}>
                  Ваш номер в очереди:
                </div>
                <div className={cls.complete_TicketNumber}>{countDown}</div>
                <div className={cls.complete_TicketRegisteredContainer}>
                  <div className={cls.complete_TicketRegistered}>
                    Зарегистрирован
                  </div>
                  <div className={cls.complete_TicketRegisteredTime}>
                    {regDate[0]}.{regDate[1]}.{regDate[2]} {regDate[3]}:
                    {regDate[4]}
                  </div>
                </div>
                <div className={cls.complete_TicketCallHoursContainer}>
                  <div className={cls.complete_TicketCallHoursHead}>
                    Время звонка
                  </div>
                  <div className={cls.complete_TicketCallHours}>
                    {info?.callHours}
                  </div>
                </div>
              </div>
            </div>
            <div className={cls.complete_LeftBottom}></div>
          </div>
          <div className={cls.complete_RightContainer}>
            <div className={cls.complete_RightTop}>
              {selectedRandomData.map(
                ({
                  id,
                  fullName,
                  transactionNumber,
                  transactionValue,
                  transactionTime,
                }) => (
                  <div
                    key={id}
                    className={`${cls.bounce7} ${cls.complete_RightTopBox} `}
                  >
                    <div className={cls.complete_RightTopBoxLeft}>
                      <div className={cls.complete_TopBoxName}>
                        {fullName}
                      </div>
                      <div className={cls.complete_TopBoxNumbers}>
                        {transactionNumber}
                      </div>
                    </div>
                    <div className={cls.complete_RightTopBoxRight}>
                      <div className={cls.complete_TopBoxValue}>
                        {transactionValue}
                      </div>
                      <div className={cls.complete_TopBoxTime}>
                        {transactionTime}
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
            <div className={cls.complete_MiddleText}>
              <div>Регистрация почти завершена...</div>
              <div>
                Еще несколько шагов, и Вы сможете получать пассивный доход на
                инвестициях!
              </div>
            </div>
            <div className={cls.complete_Video}>
              {' '}
              <Stream
                controls
                src={videoIdOrSignedToken}
                autoplay={true}
                width={'100%'}
                muted={true}
              />
            </div>
            <div className={cls.complete_Bottom}>
              <div className={cls.complete_BottomAlert}>
                <div className={cls.complete_BottomLeft}>
                  <div className={cls.complete_BottomHead}>ВНИМАНИЕ!</div>
                  <div className={cls.complete_BottomDesc}>
                    Обязательно возьмите трубку при звонке Менеджера Freedom
                    Finance, иначе Ваше место в очереди будет потеряно!
                  </div>
                </div>
                <div className={cls.complete_BottomRight}>
                  <img src={AlertIcon} alt="alert" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
