import React, { ButtonHTMLAttributes } from 'react'

import classNames from 'shared/lib/class-names'

import styles from './Button.module.scss'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'variant2'
  className?: string
}

export function Button(props: IButton) {
  const { variant = 'default', className } = props

  return (
    <button
      {...props}
      className={classNames(styles.button, styles[variant], className)}
    />
  )
}
