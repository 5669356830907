import { useParams } from 'react-router-dom'

import useAppContext from 'app/store/useAppContext'
import { Logo } from 'shared/ui/logo'
import InvestImg from 'shared/assets/images/chat/InvestImg.webp'

import { promoData } from '../model/promo'
import { QuestionsBlock } from './Questions/Questions'
import { Manager } from './Questions/Manager/Manager'

import cls from './QuestionsPage.module.scss'

export const QuestionsPage = () => {
  const { id } = useParams()
  const numberId = id ? Number(id) : 1

  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section className={cls.page}>
      <div className={cls.container}>
        <div className={cls.left_block}>
          {!isMobile && <Logo className={cls.logo} />}

          <QuestionsBlock paramsId={numberId} isMobile={isMobile} />
        </div>
        {!isMobile && (
          <div className={cls.right_block}>
            <Manager paramsId={numberId} />
            <div className={cls.gift}>
              <p>К сумме первого депозита</p>
              <h3>{promoData.firstDepositSum}</h3>
              <img src={InvestImg} alt="InvestImg" />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
