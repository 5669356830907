import { QuestionsFooter } from 'pages/QuestionsPage/ui/Questions/QuestionsFooter/QuestionsFooter'
import Layout from 'widgets/_layout'

import cls from './Footer.module.scss'

export default function Footer() {
  return (
    <footer className={cls.footer}>
      <Layout className={cls.layout}>
        <QuestionsFooter />
      </Layout>
    </footer>
  )
}
