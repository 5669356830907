import Select, { components } from 'react-select'

type callOptionsType = { label: string; value: string }

interface ISelectCallHoursProps {
  callOptions: callOptionsType[]
  handleSelectChange: (selectedOption: any) => void
}

export function SelectCallHours(props: ISelectCallHoursProps) {
  const { callOptions, handleSelectChange } = props

  const Control = ({ children, ...props }: any) => (
    <components.Control {...props}>Позвонить{children}</components.Control>
  )

  const customStyles = {
    option: (base: any, state: any) => ({
      ...base,
      color: state.isSelected ? '#fff' : '#000',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      backgroundColor: state.isSelected ? '#51AF3D' : '#fff',
      cursor: 'pointer',
    }),
    control: (base: any) => ({
      ...base,
      padding: '0 0 0 16px',
      height: '48px',
      color: '#000',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '1px',
      borderRadius: '0',
      border: '1px solid #D1D2D4',
      cursor: 'pointer',
    }),
  }

  return (
    <Select
      onChange={handleSelectChange}
      options={callOptions}
      defaultValue={callOptions[1]}
      components={{ Control }}
      styles={customStyles}
    />
  )
}
