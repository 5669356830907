import { useState } from 'react'

import useAppContext from 'app/store/useAppContext'
import { setUserQuestions } from 'app/store/actions'

import { QuestionProps } from '../../../types/questions'

import cls from './Question4.module.scss'

export const Question4 = (props: QuestionProps) => {
  const { questionNumber, questions } = props

  const {
    store: {
      user: { questions: questionsStore },
    },
    dispatch,
  } = useAppContext()

  const option1 = questions?.[questionNumber]?.options?.[0]
  const option2 = questions?.[questionNumber]?.options?.[1]
  const option3 = questions?.[questionNumber]?.options?.[2]
  const option4 = questions?.[questionNumber]?.options?.[3]

  const initialValue = questionsStore?.q4?.answer ?? null

  const [selectedOption, setSelectedOption] = useState(initialValue)

  const handleOptionChange = (event: any) => {
    const value = event?.target?.value

    setSelectedOption(value)

    const question = questions?.[questionNumber]?.text
    const answer = value ? value : ''

    if (question && answer) {
      dispatch(
        setUserQuestions({
          q4: {
            question,
            answer,
          },
        }),
      )
    }
  }

  return (
    <div className={cls.inputs}>
      <div>
        <div className={cls.input_container}>
          <input
            type="radio"
            id="option1"
            name="options"
            value={option1}
            checked={selectedOption === option1}
            onChange={handleOptionChange}
          />
          <label htmlFor="option1">{option1}</label>
        </div>
        <div className={cls.input_container}>
          <input
            type="radio"
            id="option2"
            name="options"
            value={option2}
            checked={selectedOption === option2}
            onChange={handleOptionChange}
          />
          <label htmlFor="option2">{option2}</label>
        </div>
      </div>
      <div>
        <div className={cls.input_container}>
          <input
            type="radio"
            id="option3"
            name="options"
            value={option3}
            checked={selectedOption === option3}
            onChange={handleOptionChange}
          />
          <label htmlFor="option3">{option3}</label>
        </div>
        <div className={cls.input_container}>
          <input
            type="radio"
            id="option4"
            name="options"
            value={option4}
            checked={selectedOption === option4}
            onChange={handleOptionChange}
          />
          <label htmlFor="option4">{option4}</label>
        </div>
      </div>
    </div>
  )
}
