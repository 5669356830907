import { useState } from 'react'
import classNames from 'classnames'

import useAppContext from 'app/store/useAppContext'
import { setUserQuestions } from 'app/store/actions'
import Question1Img1Icon from 'shared/assets/images/questions/1/Img1.webp'
import Question1Img2Icon from 'shared/assets/images/questions/1/Img2.webp'

import { QuestionProps } from '../../../types/questions'

import cls from './Question1.module.scss'

export const Question1 = (props: QuestionProps) => {
  const { questionNumber, questions } = props

  const {
    store: {
      isMobile,
      user: { questions: questionsStore },
    },
    dispatch,
  } = useAppContext()

  const selectedOption1 = questions?.[questionNumber]?.options[0]
  const selectedOption2 = questions?.[questionNumber]?.options[1]
  const storeValue = questionsStore?.q1?.answer
  const initialFirst = storeValue === selectedOption1 ? 1 : null
  const initialSecond = storeValue === selectedOption2 ? 2 : null
  const initialValue = initialFirst ?? initialSecond ?? 0

  const [active, setActive] = useState<number>(initialValue)

  const selectAnswer = (num: number) => {
    setActive(num)

    const question = questions?.[questionNumber]?.text
    const answer = num ? questions?.[questionNumber]?.options?.[num - 1] : ''

    if (question && answer) {
      dispatch(
        setUserQuestions({
          q1: {
            question,
            answer,
          },
        }),
      )
    }
  }

  return (
    <div className={cls.content}>
      <div
        className={classNames(cls.card, active === 1 && cls.card__active)}
        onClick={() => selectAnswer(1)}
      >
        <img src={Question1Img1Icon} alt="Question1Img1Icon" />
        <p>{selectedOption1}</p>
      </div>
      <div
        className={classNames(cls.card, active === 2 && cls.card__active)}
        onClick={() => selectAnswer(2)}
      >
        <img src={Question1Img2Icon} alt="Question1Img2Icon" />
        <p>{selectedOption2}</p>
      </div>
    </div>
  )
}
