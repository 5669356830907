import { ChangeEvent, useEffect, useState } from 'react'

import useAppContext from 'app/store/useAppContext'
import { setUserQuestions } from 'app/store/actions'
import { useDebounce } from 'shared/utils/hooks'

import type { QuestionProps } from '../../../types/questions'
import { investSum } from '../../../model/schema'

import cls from './Question2.module.scss'

export const Question2 = (props: QuestionProps) => {
  const { questionNumber, questions } = props

  const {
    store: {
      user: { questions: questionsStore },
    },
    dispatch,
  } = useAppContext()

  const storeValue = questionsStore?.q2?.answer
  const numberStoreValue = storeValue ? Number(storeValue) : null
  const initialValue = numberStoreValue ?? investSum.initial

  const [value, setValue] = useState(initialValue)

  const debouncedValue = useDebounce(value, 500)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value)
    setValue(newValue)
  }

  useEffect(() => {
    const question = questions?.[questionNumber]?.text
    const answer = debouncedValue ? debouncedValue.toString() : ''

    if (question && answer) {
      dispatch(
        setUserQuestions({
          q2: {
            question,
            answer,
          },
        }),
      )
    }
  }, [debouncedValue, dispatch, questionNumber, questions])

  return (
    <div className={cls.slider}>
      <div className={cls.slider__value}>
        <p>{value?.toLocaleString()} {investSum.currency}</p>
      </div>
      <input
        className={cls.slider__input}
        type="range"
        min={investSum.min}
        max={investSum.max}
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}
