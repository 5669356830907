type resultsDataType = {
  id: number
  fullName: string
  transactionNumber: string
  transactionValue: string
  transactionTime: string
}

const uzClients: resultsDataType[] = [
  {
    id: 1,
    fullName: 'Абдуллаев Ш.М.',
    transactionNumber: 'Дивиденды ***3248',
    transactionValue: '+4 500 000 сум',
    transactionTime: '09:18',
  },
  {
    id: 2,
    fullName: 'Турсунов Р.А.',
    transactionNumber: 'Дивиденды ***5496',
    transactionValue: '+5 200 000 сум',
    transactionTime: '14:37',
  },
  {
    id: 3,
    fullName: 'Жумаев Ф.С.',
    transactionNumber: 'Дивиденды ***1685',
    transactionValue: '+6 800 000 сум',
    transactionTime: '10:45',
  },
  {
    id: 4,
    fullName: 'Исмаилов Л.Х.',
    transactionNumber: 'Дивиденды ***2598',
    transactionValue: '+4 750 000 сум',
    transactionTime: '16:20',
  },
  {
    id: 5,
    fullName: 'Саидов Ш.М.',
    transactionNumber: 'Дивиденды ***6043',
    transactionValue: '+7 300 000 сум',
    transactionTime: '12:03',
  },
  {
    id: 6,
    fullName: 'Узаков М.А.',
    transactionNumber: 'Дивиденды ***7489',
    transactionValue: '+6 000 000 сум',
    transactionTime: '11:29',
  },
  {
    id: 7,
    fullName: 'Назаров А.А.',
    transactionNumber: 'Дивиденды ***3712',
    transactionValue: '+4 300 000 сум',
    transactionTime: '15:45',
  },
  {
    id: 8,
    fullName: 'Темиров А.У.',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+7 800 000 сум',
    transactionTime: '13:10',
  },
  {
    id: 9,
    fullName: 'Рахматов Ш.Р.',
    transactionNumber: 'Дивиденды ***5137',
    transactionValue: '+5 600 000 сум',
    transactionTime: '08:57',
  },
  {
    id: 10,
    fullName: 'Садуллаев М.Т.',
    transactionNumber: 'Дивиденды ***1956',
    transactionValue: '+4 900 000 сум',
    transactionTime: '17:12',
  },
  {
    id: 11,
    fullName: 'Шарипов Х.А.',
    transactionNumber: 'Дивиденды ***6743',
    transactionValue: '+6 400 000 сум',
    transactionTime: '14:02',
  },
  {
    id: 12,
    fullName: 'Ибрагимов А.И.',
    transactionNumber: 'Дивиденды ***3621',
    transactionValue: '+7 000 000 сум',
    transactionTime: '10:35',
  },
  {
    id: 13,
    fullName: 'Умаров Р.Д.',
    transactionNumber: 'Дивиденды ***8074',
    transactionValue: '+5 500 000 сум',
    transactionTime: '12:46',
  },
  {
    id: 14,
    fullName: 'Хакимов Б.О.',
    transactionNumber: 'Дивиденды ***4298',
    transactionValue: '+4 400 000 сум',
    transactionTime: '16:58',
  },
  {
    id: 15,
    fullName: 'Абдуллаев Д.Н.',
    transactionNumber: 'Дивиденды ***7512',
    transactionValue: '+7 500 000 сум',
    transactionTime: '09:23',
  },
  {
    id: 16,
    fullName: 'Мирзаев Ш.Р.',
    transactionNumber: 'Дивиденды ***5864',
    transactionValue: '+5 800 000 сум',
    transactionTime: '11:55',
  },
  {
    id: 17,
    fullName: 'Садуллаев Н.Ф.',
    transactionNumber: 'Дивиденды ***9430',
    transactionValue: '+4 600 000 сум',
    transactionTime: '15:14',
  },
  {
    id: 18,
    fullName: 'Рустамов У.Х.',
    transactionNumber: 'Дивиденды ***3102',
    transactionValue: '+6 600 000 сум',
    transactionTime: '13:28',
  },
  {
    id: 19,
    fullName: 'Якубов З.М.',
    transactionNumber: 'Дивиденды ***1786',
    transactionValue: '+4 700 000 сум',
    transactionTime: '17:35',
  },
  {
    id: 20,
    fullName: 'Кудратов А.Р.',
    transactionNumber: 'Дивиденды ***6975',
    transactionValue: '+5 300 000 сум',
    transactionTime: '08:42',
  },
]

export const data = uzClients
