import { NavLink, useLocation } from 'react-router-dom'

import { ROUTES } from 'shared/config/routes'
import { getCurrentDate } from 'shared/utils/functions'
import { Button } from 'shared/ui/button'
import CongratulateIcon from 'shared/assets/icons/CongratulateIcon.png'

import { giftData } from '../model/gift'

import cls from './GiftPage.module.scss'

export const GiftPage = () => {
  const location = useLocation()
  const currenDate = getCurrentDate()

  return (
    <section className={cls.page}>
      <div className={cls.container}>
        <div className={cls.gift_data}>
          <img src={CongratulateIcon} alt="CongratulateIcon" />
          <h3>Спасибо за ответы!</h3>
          <h3>Мы дарим Вам</h3>
          <h2>{giftData.giveSum}</h2>
          <p>Заберите Ваш подарок до {currenDate}</p>
          <p>- он будет начислен к сумме первого депозита</p>
        </div>
        <div className={cls.cards}>
          <div>
            <div className={cls.cards__card}>
              <p>Ежемесячный доход</p>
              <p>
                <span>{giftData.monthSum}</span>
              </p>
            </div>
          </div>
          <div className={cls.cards__bottom_block}>
            <div className={cls.cards__card}>
              <p>Размер комиссии</p>
              <p>
                <span className={cls.card_desc}>0%</span>
              </p>
            </div>
            <div className={cls.cards__card}>
              <p>Начальная сумма</p>
              <p>
                <span className={cls.card_desc}>{giftData.startSum}</span>
              </p>
            </div>
          </div>
        </div>
        <NavLink to={{
          pathname: ROUTES.CONTACTS,
          search: location.search,
        }} className={cls.button}>
          <Button>Забрать подарок</Button>
        </NavLink>
      </div>
    </section>
  )
}
